import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../contexts/appContext";
import Button from "./button";
import TaskFormatter_byTask from "../../utils/taskFormatter_byTask";
import ItemActionableElementV2 from "../displayElements/items/itemActionableElementV2";
import ActionNextStepsDisplayV2 from "../displayElements/actionDisplay/actionNextStepsDisplayV2";
import DateDisplay from "./dateDisplay";
import StringShortenDisplay from "./stringShortenDisplay";
import { GetTimeString } from "../displayElements/actionDisplay/actionTimesheetDetailsDisplay";
import { useInit } from "../hooks/useInit";
import IconSpinner from "./iconSpinner";
import { IoDocumentSharp } from "react-icons/io5";
import { MdGrading } from "react-icons/md";
import ItemStatus from "./itemStatus";
import JobFormatter from "../../utils/jobFormatter";
import JobDashboardElement from "../dashboard/jobDashboardElement";
import JobModalDetailDisplay from "../displayElements/jobs/jobModalDetailDesiplay";
import axios from "axios";

import Modal from "./modal";

const JobAndWorkflow = ({ setDelete, filter, jobView, activityRange, filterTags, isAll, activeTracerId, legacy }) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);

  const [allJobs, setAllJobs] = useState();
  const [allJobsViewHandler, setAllJobsViewHandler] = useState();
  const timeSheet = allJobs && allJobs.timesheet;



  useEffect(() => {
    initStarted(1, 1);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/job/getall`,
        {
          tagFilter: filterTags,
          isAll: isAll,
          range: activityRange,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response, "all jobs response")
        setAllJobs(response.data);
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  }, [appData.tagFilter, appData.jobs, appData.tasks, activityRange]);

  return (
    <>
      <div className="h-full w-full overflow-auto scrollbar ">
        {initState[1].started ? (
          <div className="h-full w-full flex items-start justify-center mt-2">
            <IconSpinner size="m" />
          </div>
        ) : (
          <div></div>
        )}
        {allJobs?.success &&
          !initState[1].started &&
          initState[1].initialised &&
          Object.values(JobFormatter(allJobs.jobs, filter, appData)).map((job, index) => {
            {
              return (
                <div key={job._id ?? index} className="mx-1">
                  <JobDashboardElement
                    primaryColour={"bg-taskinatorGreen"}
                    primaryBorder={"border-l-taskinatorGreen"}
                    job={job}
                    textColour={"text-taskinatorGreen"}
                    setAllJobsViewHandler={setAllJobsViewHandler}
                  />
                </div>
              );
            }
          })}

        {allJobs?.success &&
          !initState[1].started &&
          initState[1].initialised &&
          Object.values(TaskFormatter_byTask(allJobs.tasks, filter, appData)).map((jobActionWorkflow, index) => {
            {
              const deleted = jobActionWorkflow.taskStatus && jobActionWorkflow.taskStatus.closed && jobActionWorkflow.taskStatus.closedReason === "DELETED";

              return (
                <div key={jobActionWorkflow.tid} className={` ${activeTracerId ? "" : "border border-taskinatorDarkGrey border-1 bg-taskinatorWhite mb-2 overflow-hidden rounded-lg"}    `}>
                  {!activeTracerId && (
                    <div className="flex capitalize text-sm items-center px-2 bg-taskinatorDarkGrey  text-taskinatorWhite py-2 ">
                      <div className="flex pr-2"> Job Ref:</div>
                      <div className="font-bold uppercase text-taskinatorWhite border py-1 px-2 rounded-lg">{StringShortenDisplay(20, jobActionWorkflow.ref)} </div>
                      {jobView && jobActionWorkflow.project && (
                        <div className="ml-1 w-fit    text-taskinatorPurple border-taskinatorPurple border rounded font-medium uppercase py-1 px-2 rounded-lg ">
                          {" "}
                          {StringShortenDisplay(15, jobActionWorkflow.project.ref)}
                        </div>
                      )}
                      <div className={`pl-4 font-medium ${deleted ? "text-taskinatorRed" : "text-taskinatorGreen"}`}>
                        {jobActionWorkflow.taskStatus && jobActionWorkflow.taskStatus.closed && (
                          <div>
                            {jobActionWorkflow.taskStatus.closedReason} - {DateDisplay(jobActionWorkflow.taskStatus.closedDate)}
                          </div>
                        )}
                      </div>
                      {setDelete && (
                        <div className="flex justify-end grow items-center ">
                          <Button
                            label={`Remove Job`}
                            customClasses={`cursor-pointer text-taskinatorRed hover:bg-taskinatorRed hover:text-taskinatorWhite border-taskinatorRed  justify-end h-8  text-sm px-4 py-2  border  rounded opacity-40 `}
                            onClick={() => setDelete(jobActionWorkflow.tid)}
                            // error={initState[1].error}
                            // disabled={initState[1].error}
                          />
                        </div>
                      )}
                      <div className="flex justify-end grow items-center">
                        {jobActionWorkflow?.onHold ? (
                          <div className="mr-1 w-fit text-xs border-taskinatorRed border  px-2 text-taskinatorWhite bg-taskinatorRed rounded font-medium uppercase py-0.5 box-border">ON HOLD</div>
                        ) : (
                          <></>
                        )}
                        <span className="text-taskiantorWhite">Total Time: &nbsp;</span>
                        <span className="text-taskinatorBlue font-bold">
                          {GetTimeString(timeSheet?.[jobActionWorkflow._id] ?? 0, false, true)}
                          &nbsp;
                        </span>
                        <span className="text-taskiantorWhite">Of : &nbsp;</span>
                        <span className="text-taskinatorBlue font-bold">
                          {GetTimeString(Number(jobActionWorkflow?.estimatedTime ?? 0) * 3600, false, true)}
                          &nbsp;
                        </span>
                      </div>
                      {/* <div className=" px-2">|</div>
              <div className={`text-taskinatorGreen font-semibold uppercase  `}>{StringShortenDisplay(20, jobActionWorkflow.actionName)}</div>
              <div className="text-taskinatorDarkGrey font-medium pl-1">({StringShortenDisplay(15, jobActionWorkflow.workflowName)})</div> */}
                    </div>
                  )}
                  {Object.values(jobActionWorkflow.items).map((item) => {
                    return (
                      <div className={`${activeTracerId ? "m-1 mb-2 shadow rounded-lg " : "m-1 mb-2 shadow rounded-lg"}`}>
                        <div className="flex items-center">
                          {/* <div className="text-sm font-bold px-1 whitespace-nowrap text-taskinatorDarkGrey uppercase ">Focus:</div> */}
                          {item?.item && (
                            <div className=" text-xs flex items-center text-taskinatorDarkGrey  bg-taskinatorItemBlue px-4 py-2  w-full rounded-b-none rounded-t ">
                              <IoDocumentSharp size={"1rem"} />
                              <div className="px-1">|</div>
                              <div className=" font-semibold uppercase ">{item?.item?.currentItemDetails?.itemNumber}</div>
                              <div className="px-1">-</div>
                              <div className=" font-semibold uppercase pr-2">{StringShortenDisplay(20, item?.item?.currentItemDetails?.itemName)}</div>
                              <div className=" pr-2">|</div>
                              <div className="pr-2">
                                {item?.item.draft ? <ItemStatus status={item?.item.draft.status} /> : item?.item.released ? <ItemStatus status={item?.item.released.status} /> : "No Release"}
                              </div>
                              <div className="pr-2 ">Rev: </div>
                              <div className=" font-semibold uppercase pr-2 ">{item?.item.draft ? item?.item.draft.revision : item?.item.released ? item?.item.released.revision : ""}</div>
                            </div>
                          )}

                          {!item?.item && (
                            <div className="w-full text-xs flex justify-start items-center text-taskinatorDarkGrey ">
                              {item?.note ? (
                                <div className=" text-xs flex items-center text-taskinatorDarkGrey border border-2 border-taskinatorItemBlue px-4 py-2 my-1 w-full rounded ">
                                  <MdGrading size={"1rem"} /> <div className="px-1">|</div> {StringShortenDisplay(30, item.note)}
                                </div>
                              ) : (
                                <div className=" text-xs flex items-center text-taskinatorDarkGrey border rounded-b-none border-2 border-taskinatorMedGrey bg-taskinatorLightGrey px-4 py-2  w-full rounded uppercase font-medium">
                                  {" "}
                                  No Drawings{" "}
                                </div>
                              )}{" "}
                            </div>
                          )}

                          {/* {item?.note && <div></div>}
                      {!item?.item && !item?.note && <div></div>} */}

                          {/* <ItemActionableElementV2 viewHistory item={item} /> */}
                        </div>
                        <div className={`border-2 border-t-0 ${!item?.item && !item?.notes ? "border-taskinatorMedGrey" : "border-taskinatorItemBlue"} rounded-b-lg`}>
                          {item.tracers.map((tracer) => {
                            // if (tracer._id.toString() !== activeTracerId) {
                            return (
                              <div className="p-1 ">
                                <div className="flex  text-sm uppercase whitespace-nowrap items-center  ">
                                  <b className=" border-taskinatorWhite px-1"> Workflow —</b> {StringShortenDisplay(30, tracer.wfid.name)}
                                  {tracer._id.toString() === activeTracerId && (
                                    <div className="mx-2 border border-taskiantorGreen text-taskinatorGreen font-medium rounded py-1 px-2">Currently Selected</div>
                                  )}
                                </div>
                                {/* <div>Workflow name: {tracer.wfid.name}</div> */}
                                <div
                                  onClick={() => {
                                    appData.setActionViewHandler({
                                      tracerId: tracer._id,
                                      tid: tracer.tid,
                                    });
                                  }}
                                  className={`cursor-pointer hover:bg-taskinatorGreenHover border border-taskinatorMedGrey ${
                                    tracer._id.toString() === activeTracerId ? "bg-taskinatorGreenHover" : "bg-taskinatorWhite"
                                  }   rounded-lg p-2 mt-1 h-fit overflow-auto `}
                                >
                                  <ActionNextStepsDisplayV2 actionPlan={tracer.actionPlan} tracer={tracer} aidDetails={tracer.aid} displayOnly activeTracerId={activeTracerId} />
                                </div>{" "}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }
          })}
      </div>

      {allJobsViewHandler && (
        <Modal
          size={"max"}
          bgColour="bg-taskinatorWhite "
          local={() => {
            setAllJobsViewHandler();
          }}
        >
          <JobModalDetailDisplay allJobsViewHandler={allJobsViewHandler} allJobsSelectedJobData={allJobs.jobs} />
        </Modal>
      )}
    </>
  );
};

export default JobAndWorkflow;

// Object.values(jobActionWorkflow.tracers).map((tracer) => {
//   const item = tracer.itemsOnTracer;

//   if (!activeTracerId || tracer._id.toString() !== activeTracerId) {
//     return (
//       <div key={tracer._id} className={`mx-2 my-1 border bg-clip-border border-taskinatorMedGrey rounded-lg text-taskinatorDarkGrey bg-taskinatorWhite`}>
//         <ItemActionableElementV2
//           viewHistory
//           tracer={tracer}
//           item={item}
//           displayOnly={() =>
//             appData.setActionViewHandler({
//               tracerId: tracer._id,
//               tid: tracer.tid,
//             })
//           }
//         />

//         <ActionNextStepsDisplayV2 actionPlan={tracer.actionPlan} tracer={tracer} aidDetails={tracer.aid} displayOnly />
//       </div>
//     );
//   }
// })
// }
